import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import './App.css';

function App() {
    const colStyle = {
        width: '384px',
        float: 'left',
        margin: '0 0 80px 14px',
        fontSize: '1rem',
        display: 'block',
        unicodeBidi: 'isolate'
    };
    const pStyle = {
        lineHeight: '20px',
        display: 'block',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        unicodeBidi: 'isolate',
        margin: '0',
        color: '#9f9f9f'
    };
    const liStyle = {
        display: 'list-item',
        textAlign: '-webkit-match-parent',
        unicodeBidi: 'isolate',
        float: 'left',
        listStyleType: 'none'
    };
    const aStyle = {
        color: '#3d3d3d',
        textDecoration: 'none',
        fontSize: '.875rem',
        padding: '3px',
    };

    return (
      <div>
          <header>
              <div style={{ display: 'inline-flex' }}>
                  <a href="https://www.ific.ca/en" style={{ paddingLeft: '10px' }}>
                      <img alt="IFIC logo" src="https://www.ific.ca/wp-content/themes/ific-new/assets/img/logo.png"/>
                  </a>
              </div>
          </header>
          <div className="App">
              <SwaggerUI url="./swagger-config.yaml"/>
          </div>
          <footer
              style={{
                  color: '#3d3d3d',
                  backgroundColor: '#ebedee',
                  width: '90%',
                  padding: '50px 40px 40px',
                  fontSize: '1rem',
                  fontFamily: 'Droid Sans, sans-serif',
                  display: 'table'
              }}
          >
              <div style={colStyle}>
                  <strong>The Investment Funds Institute of Canada</strong>
                  <p style={pStyle}>333 Bay Street, Suite 2601</p>
                  <p style={pStyle}>Toronto, Ontario M5H 2R2</p>
                  <p style={pStyle}>General: 416-363-2150</p>
                  <p style={pStyle}>Toll free: 1-866-347-1961</p>
              </div>
              <div style={colStyle}>
                  <strong>IFIC Research and Statistics</strong>
                  <p style={pStyle}>
                      <strong>Support</strong>
                  </p>
                  <p style={pStyle}>
                      <a style={{color: '#9f9f9f'}} href="mailto:statistics@ific.ca">statistics@ific.ca</a>
                  </p>
                  <p style={pStyle}>
                      <strong>Sales</strong>
                  </p>
                  <p style={pStyle}>416-309-2312</p>
              </div>
              <div style={{borderBottom: 'solid 1px #6c777d', clear: 'both'}}></div>
              <ul
                  style={{
                      margin: '10px 0 0',
                      padding: '0',
                      float: 'left',
                      display: 'block',
                      listStyleType: 'disc',
                      marginBlockStart: '1em',
                      marginBlockEnd: '1em',
                      marginInlineStart: '0px',
                      marginInlineEnd: '0px',
                      paddingInlineStart: '40px',
                      unicodeBidi: 'isolate'
                  }}
              >
                  <li style={liStyle}>
                      <a style={aStyle} href="javascript:void(0)">Privacy Policy |</a>
                  </li>
                  <li style={liStyle}>
                      <a style={aStyle} href="https://www.ific.ca/en">IFIC Website |</a>
                  </li>
                  <li style={liStyle}>
                      <a style={aStyle} href="javascript:void(0)">Terms of Use</a>
                  </li>
              </ul>
              <ul
                  style={{
                      margin: '10px 0 0',
                      padding: '0',
                      float: 'right',
                      display: 'block',
                      listStyleType: 'disc',
                      marginBlockStart: '1em',
                      marginBlockEnd: '1em',
                      marginInlineStart: '0px',
                      marginInlineEnd: '0px',
                      paddingInlineStart: '40px',
                      unicodeBidi: 'isolate'
                  }}
              >
                  <li style={liStyle}>© 2024 The Investment Funds Institute of Canada. All rights reserved.</li>
              </ul>
          </footer>
      </div>
  );
}

export default App;
